@font-face {
  font-family: "Beach Bar";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local("Beach Bar"), url("../fonts/beachBar/beachBar.ttf") format("truetype");
}

/* montserrat-100normal - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local("Montserrat Thin "), local("Montserrat-Thin"),
    url("../fonts/montserrat/montserrat-latin-100.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-100.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-100italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: local("Montserrat Thin italic"), local("Montserrat-Thinitalic"),
    url("../fonts/montserrat/montserrat-latin-100italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-100italic.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-200normal - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: local("Montserrat Extra Light "), local("Montserrat-Extra Light"),
    url("../fonts/montserrat/montserrat-latin-200.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-200.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-200italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src: local("Montserrat Extra Light italic"), local("Montserrat-Extra Lightitalic"),
    url("../fonts/montserrat/montserrat-latin-200italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-200italic.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-300normal - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Montserrat Light "), local("Montserrat-Light"),
    url("../fonts/montserrat/montserrat-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-300.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-300italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local("Montserrat Light italic"), local("Montserrat-Lightitalic"),
    url("../fonts/montserrat/montserrat-latin-300italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-300italic.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-400normal - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Montserrat Regular "), local("Montserrat-Regular"),
    url("../fonts/montserrat/montserrat-latin-400.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-400.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-400italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local("Montserrat Regular italic"), local("Montserrat-Regularitalic"),
    url("../fonts/montserrat/montserrat-latin-400italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-400italic.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-500normal - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Montserrat Medium "), local("Montserrat-Medium"),
    url("../fonts/montserrat/montserrat-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-500.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-500italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local("Montserrat Medium italic"), local("Montserrat-Mediumitalic"),
    url("../fonts/montserrat/montserrat-latin-500italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-500italic.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-600normal - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local("Montserrat SemiBold "), local("Montserrat-SemiBold"),
    url("../fonts/montserrat/montserrat-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-600.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-600italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: local("Montserrat SemiBold italic"), local("Montserrat-SemiBolditalic"),
    url("../fonts/montserrat/montserrat-latin-600italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-600italic.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-700normal - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Montserrat Bold "), local("Montserrat-Bold"),
    url("../fonts/montserrat/montserrat-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-700.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-700italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local("Montserrat Bold italic"), local("Montserrat-Bolditalic"),
    url("../fonts/montserrat/montserrat-latin-700italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-700italic.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-800normal - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: local("Montserrat ExtraBold "), local("Montserrat-ExtraBold"),
    url("../fonts/montserrat/montserrat-latin-800.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-800.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-800italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: local("Montserrat ExtraBold italic"), local("Montserrat-ExtraBolditalic"),
    url("../fonts/montserrat/montserrat-latin-800italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-800italic.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-900normal - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local("Montserrat Black "), local("Montserrat-Black"),
    url("../fonts/montserrat/montserrat-latin-900.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-900.woff") format("woff"); /* Modern Browsers */
}

/* montserrat-900italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: local("Montserrat Black italic"), local("Montserrat-Blackitalic"),
    url("../fonts/montserrat/montserrat-latin-900italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/montserrat/montserrat-latin-900italic.woff") format("woff"); /* Modern Browsers */
}

// NEW FONT
@font-face {
  font-family: "PP NeueMontreal";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-Regular.otf), url(../fonts/neue-montreal/web/PPNeueMontreal-Regular.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-Regular.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "PP NeueMontreal";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-Italic.otf), url(../fonts/neue-montreal/web/PPNeueMontreal-Italic.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-Italic.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-Italic.ttf) format("truetype");
  font-style: italic;
}
@font-face {
  font-family: "PP NeueMontreal";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-Thin.otf), url(../fonts/neue-montreal/web/PPNeueMontreal-Thin.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-Thin.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-Thin.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "PP NeueMontreal";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-ThinItalic.otf),
    url(../fonts/neue-montreal/web/PPNeueMontreal-ThinItalic.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-ThinItalic.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-ThinItalic.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "PP NeueMontreal";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-Light.otf), url(../fonts/neue-montreal/web/PPNeueMontreal-Light.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-Light.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "PP Neue Montreal";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-LightItalic.otf),
    url(../fonts/neue-montreal/web/PPNeueMontreal-LightItalic.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-LightItalic.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "PP Neue Montreal";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-Medium.otf), url(../fonts/neue-montreal/web/PPNeueMontreal-Medium.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-Medium.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-Medium.ttf) format("truetype");
  font-weight: 500;
  //font-style: normal;
}
@font-face {
  font-family: "PP Neue Montreal";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-MediumItalic.otf),
    url(../fonts/neue-montreal/web/PPNeueMontreal-MediumItalic.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-MediumItalic.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "PP Neue Montreal";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-SemiBold.otf), url(../fonts/neue-montreal/web/PPNeueMontreal-SemiBold.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-SemiBold.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "PP Neue Montreal";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-SemiBolditalic.otf),
    url(../fonts/neue-montreal/web/PPNeueMontreal-SemiBolditalic.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-SemiBolditalic.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-SemiBolditalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "PP Neue Montreal Book";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-Book.otf), url(../fonts/neue-montreal/web/PPNeueMontreal-Book.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-Book.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-Book.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "PP Neue Montreal Book";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-BookItalic.otf),
    url(../fonts/neue-montreal/web/PPNeueMontreal-BookItalic.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-BookItalic.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-BookItalic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "PP Neue Montreal";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-Bold.otf), url(../fonts/neue-montreal/web/PPNeueMontreal-Bold.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-Bold.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "PP Neue Montreal";
  src: url(../fonts/neue-montreal/otf/PPNeueMontreal-BoldItalic.otf),
    url(../fonts/neue-montreal/web/PPNeueMontreal-BoldItalic.woff2) format("woff2"),
    url(../fonts/neue-montreal/web/PPNeueMontreal-BoldItalic.woff) format("woff"),
    url(../fonts/neue-montreal/ttf/PPNeueMontreal-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}
