@import-normalize; /* bring in normalize.css styles */

//youtoo fonts
@import "scss/youtoo-fonts";

* {
    box-sizing: border-box;
    font-family: 'PP Neue Montreal', 'Open Sans', sans-serif;
}

html,
body {
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 100%;
    /* font smoother */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    /* firefox specific rule */
    @-moz-document url-prefix() {
        font-weight: lighter !important;
    }
}
